import Box from "@material-ui/core/Box"
import { Divider, Grid, Icon, Typography } from "components"
import { IconName } from "components/common/Icon"
import { env } from "configs"
import { useMemo } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { toCurrency } from "utils/helper"
import {
  ReactComponent as Coin
} from "assets/icon/coin.svg"
const Categories = styled.div<{ color: string; ml: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  padding: 2px;
  margin-left: ${(props) => props.ml};
`

const CategoriesComponent = (props: {
  type: string
  chipBackgroundColor: string
  chipTitleColor: string
  justifyContent: string
  ml: string
}) => {
  const { type, chipBackgroundColor, chipTitleColor, justifyContent, ml } = props

  if (env.USER_CAN_SEE_REMAINING_BUDGET) {
    return (
      <div
        style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-end" }}
      >
        <Categories color={chipBackgroundColor} ml={ml}>
          <Typography variant="caption" color={chipTitleColor}>
            {type} 
          </Typography>
        </Categories>
        <div style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "4px" }}>
          {/* <Coin />
          <Typography variant="subtitle1">ตามจำนวนที่จ่ายจริง</Typography> */}
        </div>
      </div>
    )
  } else {
    return (
      <Box display="flex" alignItems="center" minWidth="80px" justifyContent={justifyContent}>
        <Categories color={chipBackgroundColor} ml={ml}>
          <Typography variant="caption" color={chipTitleColor}>
            {type}
          </Typography>
        </Categories>
      </Box>
    )
  }
}

const FooterContainer = styled.div`
  bottom: 4px;
  padding: 8px 0px 0px 0px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  border-top: 1px solid ${AppColor["Gray/Flat Button Bg"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

interface FooterProps {
  totalAmount: number
  approvedRequest: number
}

const Footer = (props: FooterProps) => {
  const { totalAmount, approvedRequest } = props
  return (
    <FooterContainer>
      <Grid item xs={12} sm={12}>
        <ContainerFlex js="space-between" row al="">
          <ContainerFlex js="" al="flex-start">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Gray/Flat Button Bg">
                จำนวนครั้งที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(approvedRequest, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
          <ContainerFlex js="" al="flex-end">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Text/Gray Success">
                จำนวนเงินที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(totalAmount, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
        </ContainerFlex>
      </Grid>
    </FooterContainer>
  )
}

const FooterList = (props: any) => {
  const { budget, type, yearlyBudget, mode } = props

  const remainingBudget = type === "dental_fee" ? yearlyBudget.dentalBudget : yearlyBudget.budget
  const limitBudget = type === "dental_fee" ? yearlyBudget.companyDentalBudget : yearlyBudget.companyBudget

  return (
    <>
      {mode === "native" && budget && (
        <>
          <Divider style={{ margin: "12px 0px 8px 0px", background: AppColor["Text/Gray"] }} />
          {budget.map((detail: any, index: any) => {
            const uniqueKey = `${detail.label}-${index}`
            return (
              <Box display="flex" flexDirection="column" alignItems="flex-start" pt={1} key={uniqueKey}>
                <Typography variant="body1" color="Text/Gray Success" style={{ fontSize: "12px", lineHeight: "16px" }}>
                  {detail.label}
                </Typography>
                <Box display="flex">
                  {yearlyBudget && detail.field ? (
                    <>
                      <Typography variant="body1" color="Text/Black2" style={{ fontSize: "12px", lineHeight: "16px" }}>
                        {toCurrency(remainingBudget, {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                      </Typography>

                      <Typography
                        variant="body1"
                        color="Text/Gray Preview"
                        style={{ fontSize: "12px", lineHeight: "16px" }}
                      >
                        /{toCurrency(limitBudget, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      color="Text/Black2"
                      style={{ fontSize: "12px", lineHeight: "16px", textAlign: "left" }}
                    >
                      {detail.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          })}
        </>
      )}
    </>
  )
}

const TopComponent = (props: any) => {
  const {
    mode,
    renderText,
    icon,
    iconColor,
    type,
    chipBackgroundColor,
    chipTitleColor,
    budget,
    yearlyBudget,
    claimType,
  } = props

  const remainingBudget = claimType === "dental_fee" ? yearlyBudget.dentalBudget : yearlyBudget.budget
  const limitBudget = claimType === "dental_fee" ? yearlyBudget.companyDentalBudget : yearlyBudget.companyBudget

  const coinModuleComponent = useMemo(
    () => (
      <Box display="flex" justifyContent="space-between" mb="8px">
        <Box display="flex" alignItems="center">
          <Box padding="4px">
            <Icon width="32px" height="32px" name={icon} color={iconColor} />
          </Box>
          <div>{renderText}</div>
        </Box>
        <CategoriesComponent
          type={type}
          chipBackgroundColor={chipBackgroundColor}
          chipTitleColor={chipTitleColor}
          justifyContent=""
          ml="px"
        />
      </Box>
    ),
    [chipBackgroundColor, chipTitleColor, icon, iconColor, renderText, type],
  )

  const nativeComponent = useMemo(
    () => (
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Box padding="4px" pr="8px">
            <Icon width="32px" height="32px" name={icon} color={iconColor} />
          </Box>
          <div>{renderText}</div>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <CategoriesComponent
            type={type}
            chipBackgroundColor={chipBackgroundColor}
            chipTitleColor={chipTitleColor}
            justifyContent="flex-start"
            ml="8px"
          />
          <Box display="flex" flexDirection="column" alignItems="flex-end" ml="8px" maxWidth="150px">
            {budget && (
              <>
                {budget.map((detail: any, index: any) => {
                  const uniqueKey = `${detail.label}-${index}`
                  return (
                    <Box display="flex" justifyContent="flex-end" pt={1} key={uniqueKey}>
                      <Typography
                        variant="body1"
                        color="Text/Gray Preview"
                        style={{ fontSize: "12px", lineHeight: "16px" }}
                        align="right"
                      >
                        {`${detail.label}: ${
                          yearlyBudget && detail.field
                            ? `${toCurrency(remainingBudget, {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              })}/${toCurrency(limitBudget, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}`
                            : detail.value
                        }`}
                      </Typography>
                    </Box>
                  )
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    ),
    [
      chipBackgroundColor,
      chipTitleColor,
      icon,
      iconColor,
      renderText,
      type,
      budget,
      remainingBudget,
      limitBudget,
      yearlyBudget,
    ],
  )

  switch (mode) {
    case "native":
      return nativeComponent
    case "coin_module":
      return coinModuleComponent
    default:
      return coinModuleComponent
  }
}

const ClaimCardClickContainer = styled(Box)<{ ["display-only"]: boolean }>`
  &:hover {
    box-shadow: ${(props) => (props["display-only"] ? "none" : `-1px 0px 7px 4px ${AppColor["Primary/Light Hover"]}`)};
    cursor: ${(props) => (props["display-only"] ? "context-menu" : "pointer")};
  }
`

export interface ClaimCardProps {
  type: string
  name?: string
  title: string
  iconColor: string
  chipTitleColor: string
  chipBackgroundColor: string

  displayOnly?: boolean
  fullSize?: boolean

  icon: IconName
  onClick?: (request?: any) => void

  totalAmount: number
  approvedRequest: number
  budget?: any
  budgetValues?: any
  yearlyBudget?: any
  mode?: string
}

export const ClaimCard = (props: ClaimCardProps) => {
  const {
    totalAmount = 0,
    displayOnly = false,
    approvedRequest = 0,
    title,
    type,
    fullSize = false,
    icon,
    onClick,
    name,
    iconColor,
    chipTitleColor,
    chipBackgroundColor,
    budget,
    budgetValues = {},
    yearlyBudget,
    mode,
  } = props

  const renderText = useMemo(() => {
    const textWithNewLine = title.split("\n")

    return textWithNewLine.map((line, index) => {
      const uniqueKey = `${line}-${index}`
      return (
        <Typography
          key={uniqueKey}
          title={line}
          variant="body1"
          color="Text/Black2"
          style={{
            // whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {line}
        </Typography>
      )
    })
  }, [title])

  const Content = (
    <ClaimCardClickContainer
      title={title}
      padding="8px"
      border={`1px solid ${AppColor["Gray/Flat Button Bg"]}`}
      borderRadius={4}
      bgcolor={AppColor["White / White"]}
      display-only={displayOnly}
    >
      {fullSize ? (
        <Box display="flex" flexDirection="column" padding="8px">
          <TopComponent
            mode={mode}
            renderText={renderText}
            icon={icon}
            iconColor={iconColor}
            type={type}
            claimType={name}
            chipBackgroundColor={chipBackgroundColor}
            chipTitleColor={chipTitleColor}
            budget={budget}
            yearlyBudget={yearlyBudget}
          />
          <Footer approvedRequest={approvedRequest} totalAmount={totalAmount} />
        </Box>
      ) : (
        <Box textAlign="center" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
          <Box padding="4px" pr="8px">
            <Icon width="32px" height="32px" name={icon} color={iconColor} />
          </Box>
          <Box padding="0px 16px">
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <div>{renderText}</div>
            </Box>

            <FooterList
              budgetValues={budgetValues}
              totalAmount={totalAmount}
              budget={budget}
              type={name}
              yearlyBudget={yearlyBudget}
              mode={mode}
            />
            {env.USER_CAN_SEE_REMAINING_BUDGET && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                {/* <Coin  />
                <Typography variant="subtitle1">ตามที่จ่ายจริง</Typography> */}
              </div>
            )}
          </Box>
        </Box>
      )}
    </ClaimCardClickContainer>
  )

  return (
    <Grid item xs={fullSize ? 12 : 6} mb="8px">
      {displayOnly ? Content : <Box onClick={onClick}>{Content}</Box>}
    </Grid>
  )
}
