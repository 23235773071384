import { Box, Button, Grid, IconButton, Modal, Notification, Typography, MuiIcon, Form, Icon } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import { compose, withConfirmGoBack, withConfirmRefresh, withFormik, withHooks, withStores } from "enhancers"
import { Field } from "pages/main/claim-detail/form-field"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, isJson, paths, stringToDate, toCurrency } from "utils/helper"
import ApproveModal from "./approve-modal"
import RejectModal from "./reject-modal"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"
import { InfoValuesConfig } from "pages/main/claim-detail"
import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import { SummaryComponent } from "pages/main/claim-detail/SummaryComponent"
import { BudgetComponent } from "../BudgetComponent"
import { functions, env } from "configs"
import { IconName } from "components/common/Icon"
import { RejectReasonComponent } from "../edit/RejectReasonComponent"
import LoadingModal from "components/LoadingModal"
import { isEmpty } from "lodash"

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${AppColor["Primary/Background"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
  box-shadow: 4px 0px 8px ${AppColor["Text/Background"]};
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const RejectActionButton = styled(Button)`
  width: 100%;
  background-color: ${AppColor["White / White"]};
  &:focus {
    background-color: ${AppColor["White / White"]};
  }
  &:active {
    background-color: ${AppColor["White / White"]};
  }
  &:hover {
    background-color: ${AppColor["System/Error Light Hover"]};
  }
`

const ActionButton = styled(Button)`
  width: 100%;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${AppColor["White / White"]};
`

const WarningIcon = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["White / White"]};
  border-radius: 4px;
  margin-top: 16px;
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`

const ApprovalPageComponent = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoaded || props.imageLoading} />
    <LoadingModal isOpen={props.approveRequestLoading} title="กำลังส่งคำขอการเบิก" />
    {props.defaultTransferDate && props.isLoaded && (
      <ApproveModal
        title={props.title}
        isOpen={props.isApproveModalOpen}
        onClose={props.handleCloseApproveModal}
        onConfirm={props.handleConfirmApprove}
        amount={props.requestAmount}
        isInsurance={props.isInsurance}
        defaultTransferDate={props.defaultTransferDate}
        submitTransferFast={props.submitTransferFast}
        master={props.master}
        isPassAwayRequest={props.isPassAwayRequest}
      />
    )}

    <RejectModal
      title={props.title}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={props.requestAmount}
      rejectReasons={props.rejectReasons}
    />
    {props.isLoaded && (
      <>
        <Box style={{ backgroundColor: AppColor["Primary/Background"] }}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <DetailStatusContainer>
              <TopBox>
                <Typography variant="h2" color="White / White">
                  รายละเอียดการเบิก
                </Typography>
                <Link to={`/claim-requests?tab=${props.tab}`} onClick={props.handleClosePage}>
                  <CloseButton>
                    <MuiIcon name="Close" style={{ fontSize: "24px" }} />
                  </CloseButton>
                </Link>
              </TopBox>
              <DetailBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ผู้เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.createdBy}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ฝ่าย/สังกัด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.affiliation}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      กลุ่มพนักงาน
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.employeeGroup}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เบอร์โทร
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.phoneNumber}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      อีเมล
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.email}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เลขอ้างอิง
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.claimId}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      วันที่เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      สถานะ
                    </Typography>
                  </FrontBox>
                  <StepStatus
                    claimStatus={props.claimStatus}
                    workflows={props.workflows}
                    workflowStep={props.workflowStep}
                  />
                </FlexBox>
                <FlexBox>
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      แก้ไขล่าสุด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.updatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
              </DetailBox>
              {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
                <LightBlueBox padding="8px 16px">
                  <FlexBox>
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        เหตุผล:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectReason || "-"}
                    </Typography>
                  </FlexBox>
                  <FlexBox mt="8px">
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        หมายเหตุ:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectRemark || "-"}
                    </Typography>
                  </FlexBox>
                </LightBlueBox>
              )}
            </DetailStatusContainer>
          </Box>
        </Box>
        <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
          <ClaimDetail>
            {props.currentUserIsHr && props.isInstead && (
              <>
                <WarningBox mt="8px" mb="24px">
                  <WarningIcon name="Warning" color={AppColor["Error/Error Text"]}></WarningIcon>
                  <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                    กำลังทำรายการแทนพนักงานคนอื่น
                  </Typography>
                </WarningBox>
              </>
            )}
            <Box mt="8px">
              <ClaimCard
                title={props.title}
                type={props.type}
                name={props.name}
                approvedRequest={props.approvedCount}
                totalAmount={props.approvedAmount}
                icon={props.icon}
                iconColor={props.iconColor}
                chipTitleColor={props.chipTitleColor}
                chipBackgroundColor={props.chipBackgroundColor}
                budget={props.budgetList}
                budgetValues={props.budgets}
                yearlyBudget={props.type === "ค่ารักษาพยาบาล" ? props.yearlyBudget : {}}
                mode={env.COIN_SOURCE}
                fullSize
                displayOnly
              />
            </Box>
            {props.canEdit && props.rejectReason && (
              <RejectReasonComponent
                rejectedBy={props.rejectedBy}
                rejectReason={props.rejectReason}
                remark={props.rejectRemark}
              />
            )}
            {props.summary && props.checkSummaryDetail && (
              <SummaryComponent
                summary={props.summary}
                total={props.approvedAmount}
                inputs={props.inputs}
                values={props.claimStatus === EnumClaimRequestStatus.APPROVED ? props.detailValues : props.values}
              />
            )}
            <Title>
              <Box display="flex" textAlign="center">
                {env.ICON_CONFIG.DETAIL_LABEL && (
                  <Icon
                    style={{ marginRight: "8px" }}
                    name={env.ICON_CONFIG.DETAIL_LABEL as IconName}
                    color={AppColor["Primary/Primary Text"]}
                    width={24}
                    height={24}
                  />
                )}
                <Typography variant="h3"> {props.canEdit ? "กรอกรายละเอียดการเบิก" : "รายละเอียดการเบิก"}</Typography>
              </Box>
            </Title>
            <Form>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item xs={12} key="select-employee">
                  <Typography variant="body1" color="Text/Gray Preview">
                    ผู้ขอเบิก
                  </Typography>
                  <Field
                    isPreview={true}
                    component="SELECT"
                    label=""
                    name="requesterId"
                    value={props.requesterValues}
                  />
                </Grid>

                {props.claimFor && props.claimFor !== "Self" && (
                  <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
                    <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimFor.nameTh} />
                  </Grid>
                )}
                {props.inputs?.map((input: any) => (
                  <>
                    {!props.canEdit && input.type === INPUT_TYPE.LABEL ? (
                      <></>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        key={`${input.name}-5`}
                        style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                      >
                        <Field
                          fast={!(input.type === INPUT_TYPE.RELATION)}
                          isPreview={props.isPreview || input.readOnly}
                          component={input.type}
                          label={input.title}
                          name={input.name}
                          disabled={input.disabled}
                          options={input.options}
                          unit={input.unit}
                          placeholder={input.placeholder}
                          required={input.required}
                          icon={input.icon}
                          iconColor={input.iconColor}
                          helperText={input.remark}
                          master={props.master}
                          requester={props.requester}
                          auto={input.auto}
                          requestName={props.name}
                          values={props.values}
                          setFieldValue={props.setFieldValue}
                          value={props.detailValues[input.name]}
                          defaultValue={input.value}
                          helperTextColor={input.remarkColor}
                          maxDate={input.maxDate}
                          minDate={input.minDate}
                          claimType={props.type}
                          filterOptions={input.filterOptions}
                        />
                      </Grid>
                    )}
                  </>
                ))}
                {env.COIN_SOURCE === "native" && (
                  <BudgetComponent
                    budgetDetail={props.budgetDetail.detail}
                    budgetValues={props.budgetValues?.detail}
                    year={props.year}
                    values={props.values}
                    yearlyBudget={props.yearlyBudget}
                    type={props.name}
                  />
                )}
              </Grid>
            </Form>

            {props.documents.length > 0 && (
              <UploadDocumentCard
                documents={props.documents}
                values={props.documentValues}
                onChange={props.handleFilesChange}
                canDelete={false}
                isPreview={true}
                mb="8px"
                onLoading={(loading) => props.setImageLoading(loading)}
              />
            )}
          </ClaimDetail>
          {props.showFooter && (
            <FooterContainer>
              <Typography variant="h3" color="Text/Primary Text">
                ผลการพิจารณา
              </Typography>
              <FlexBox mt="16px" mb="8px">
                <RejectActionButton
                  variant="outlined"
                  color={AppColor["Other/Danger"]}
                  style={{ borderColor: AppColor["Other/Danger"] }}
                  onClick={props.handleOpenRejectModal}
                  mr="16px"
                  disabled={props.disabledRejectButton}
                >
                  ตรวจสอบเพิ่มเติม
                </RejectActionButton>
                <ActionButton
                  onClick={props.handleOpenApproveModal}
                  variant="contained"
                  disabled={props.disabledApproveButton}
                >
                  อนุมัติ
                </ActionButton>
              </FlexBox>
            </FooterContainer>
          )}
        </Box>
      </>
    )}
  </>
)

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
        rejectedBy
      }
    }
  `,
  APPROVE_REQUEST: gql`
    mutation APPROVE_REQUEST(
      $requestId: String!
      $isApprove: Boolean!
      $createdBy: String!
      $rejectReason: JSON
      $remark: String
      $transferDate: DateTime
      $seq: Int!
      $isTransferFast: Boolean
      $approveValues: JSON
      $info: JSON!
      $requestName: String!
      $employeeId: String!
    ) {
      approveClaimRequest(
        input: {
          requestId: $requestId
          isApprove: $isApprove
          createdBy: $createdBy
          rejectReason: $rejectReason
          remark: $remark
          transferDate: $transferDate
          seq: $seq
          isTransferFast: $isTransferFast
          approveValues: $approveValues
          info: $info
          requestName: $requestName
          employeeId: $employeeId
        }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        createdAt
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST($info: JSON!, $employeeId: String!, $requestName: String!, $requestId: String) {
      validateClaim(
        inputs: { info: $info, employeeId: $employeeId, requestName: $requestName, requestId: $requestId }
      ) {
        hasErrorMessage
      }
    }
  `,
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        master
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withConfirmRefresh(),
  withConfirmGoBack(),

  withHooks((props: any, hooks: any) => {
    const {
      currentUserInfo,
      disableConfirm,
      disableConfirmRefresh,
      enableConfirmRefresh,
      setValues,
      values,
      setFieldValue,
    } = props
    const {
      useParams,
      useQuery,
      useCallback,
      useEffect,
      useState,
      useMutation,
      useMemo,
      useLazyQuery,
      useUrlParam,
    } = hooks
    const queryParams = useUrlParam()
    const { tab } = queryParams || {}
    const { id } = useParams()

    const { data, refetch, loading: detailLoading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
      fetchPolicy: "network-only",
    })

    const { data: configs } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS)
    const [approveRequest, { error: approveError, loading: approveRequestLoading }] = useMutation(API.APPROVE_REQUEST)
    const [validateRequest, { loading: validateClaimLoading }] = useMutation(API.VALIDATE_REQUEST)
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [workflowStep, setWorkflowStep] = useState(0)
    const [submitTransferFast, setSubmitTransferFast] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const initialInfoValues: InfoValuesConfig = useMemo(
      () => ({
        inputs: {},
        documents: {},
      }),
      [],
    )
    const [infoValues, setInfoValues] = useState(initialInfoValues)
    const [detailValues, setDetailValues] = useState({})
    const [allBudgets, setAllBudgets] = useState()
    const [yearlyBudget, setYearlyBudget] = useState()
    const [imageLoading, setImageLoading] = useState(true)

    const [refetchBudget] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (budgetData: any) => {
        const current = budgetData.employeeRequestBudgets.usage[data?.claimRequestHistoryDetail.type]
        setAllBudgets(current)
      },
      fetchPolicy: "network-only",
    })

    const [refetchYearlyBudget] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (dataBudget: any) => {
        const yearlyBudgetData = dataBudget.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      fetchBudgetData(employee, values, refetchBudget)
    }, [refetchBudget, data, values.children_name])

    useEffect(() => {
      fetchYearlyBudget(employee, data, refetchYearlyBudget)
    }, [refetchYearlyBudget, data, data?.claimRequestHistoryDetail.info.type])

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const currentUserLv = useMemo(() => {
      if (currentUser && !isEmpty(currentUser.meta)) {
        if (functions.validate["findEmployeeLv"] === undefined) {
          return "unit_name_lv6"
        }
        return functions.validate["findEmployeeLv"](currentUser)
      }
      return "unit_name_lv6"
    }, [currentUser])

    const overHr = useMemo(() => {
      if (currentUserLv) return currentUserLv !== "unit_name_lv6"
      return false
    }, [currentUserLv])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const master = useMemo(() => {
      if (configs) return getValueOrDefault(configs.requestConfigs.master, {})
    }, [configs])

    const approveParams = useMemo(
      () => ({
        requestId: id,
        createdBy: currentUser?.id,
      }),
      [id, currentUser],
    )

    const isPassAwayRequest = useMemo(() => {
      const requestTitle = data?.claimRequestHistoryDetail.info.title
      const passAwayRequest = checkIsPassaway(requestTitle)
      return passAwayRequest
    }, [data?.claimRequestHistoryDetail.info.title])

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const currentActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow,
      )
      return currentWorkflow
    }, [data])

    const currentUserCanAction = useMemo(() => {
      return nextActionWorkflow?.possibleApproverIds.includes(currentUser?.id)
    }, [nextActionWorkflow, currentUser])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const requestApproved = useMemo(() => data?.claimRequestHistoryDetail.status === EnumClaimRequestStatus.APPROVED, [
      data,
    ])

    const showFooter = useMemo(() => {
      return isShowFooter(requestApproved, requestRejected, currentUserCanAction)
    }, [requestApproved, requestRejected, currentUserCanAction])

    const approvalSection = useMemo(() => {
      return mapApprovalSection(requestRejected, requestApproved, data)
    }, [requestRejected, data, requestApproved])

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const isInstead = useMemo(() => checkInstead(data), [data])

    const defaultTransferDate = useMemo(() => {
      return getTranferDate(data)
    }, [data])

    const requestAmount = useMemo(() => {
      const history = data?.claimRequestHistoryDetail
      let amountFieldName = history?.info.amountFieldName
      const amountFromInfo = getAmoutFieldNameForApprove(overHr, history, amountFieldName)
      const func = functions.calculate[history?.info.calculateAmount]
      amountFieldName = getAmountFieldName(func, history, amountFieldName)
      if (overHr) {
        return getValueOrDefault(amountFromInfo, 0)
      }
      return getValueOrDefault(infoValues?.inputs[amountFieldName], 0)
    }, [data?.claimRequestHistoryDetail, infoValues?.inputs, overHr])

    const isInsurance = useMemo(() => checkInsurance(data), [data])

    const canEdit = useMemo(() => data?.claimRequestHistoryDetail.currentWorkflow === -1, [data])

    const getValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapValue(value, type)
    }, [])

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapStringValue(value, formName, type, values)
    }, [])

    const handleClosePage = useCallback(() => {
      setConfirm(showFooter, disableConfirm)
      paths.claimRequestsPath({ tab: EnumClaimRequestStatus.WAITING }).push()
    }, [disableConfirm, showFooter])

    const handleCloseErrorModal = useCallback(
      (confirmProps: any) => {
        confirmProps.close()
        disableConfirm()
        paths.claimRequestsPath({ tab: EnumClaimRequestStatus.WAITING }).push()
        setDisabledApproveButton(false)
        setDisabledRejectButton(false)
      },
      [disableConfirm],
    )

    const onValidateError = useCallback((error: any) => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={4}>
              <MuiIcon fontSize="large" color={AppColor["Error/Error Text"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="flexStart" mb="16px">
              <List style={{ listStyleType: JSON.parse(error.message).length < 2 ? "none" : "inherit" }}>
                {JSON.parse(error.message).map((mes: any, index: number) => (
                  <li key={1150}>
                    <Typography variant="body1" color="Text/Black2">
                      {mes}
                    </Typography>
                  </li>
                ))}
              </List>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])

    const handleConfirmApprove = useCallback(
      async (date?: string, transferFast?: boolean, paymentType?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          const info = {
            ...data?.claimRequestHistoryDetail.info,
            conditions: nextActionWorkflow?.workflow.conditions,
            inputs: nextActionWorkflow?.workflow.inputs,
            values: infoValues,
            rejectReason: { message: "" },
          }

          setPaymentType(paymentType, info)

          let params: {
            isApprove: boolean
            requestId: string
            createdBy: string
            seq: number
            transferDate?: string
            isTransferFast?: boolean
          } = {
            isApprove: true,
            seq: nextActionWorkflow?.seq,
            ...approveParams,
            approveValues: infoValues,
            info,
            requestName: data?.claimRequestHistoryDetail.type,
            employeeId: data?.claimRequestHistoryDetail.employeeId,
          }

          setParams(params, date, transferFast)

          await approveRequest({ variables: { ...params } })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลแล้ว")
          disableConfirm()
          paths.claimRequestsPath({ tab: tab }).push()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          openErrorModal(e, handleCloseErrorModal)
        }
      },
      [
        data?.claimRequestHistoryDetail.info,
        data?.claimRequestHistoryDetail.type,
        data?.claimRequestHistoryDetail.employeeId,
        nextActionWorkflow?.workflow.conditions,
        nextActionWorkflow?.workflow.inputs,
        nextActionWorkflow?.seq,
        infoValues,
        approveParams,
        approveRequest,
        disableConfirm,
        handleCloseErrorModal,
        tab,
      ],
    )

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string, requestName: string, requestId: string) => {
        return checkValidateReq(validateRequest, onValidateError, info, requesterId, requestName, requestId)
      },
      [onValidateError, validateRequest],
    )

    const handleOpenApproveModal = useCallback(async () => {
      const workflowSeq = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflowSeq = data?.claimRequestHistoryDetail.currentWorkflow + 1
      const currentWorkflow = workflowSeq.find((workflow: any) => workflow.seq === currentWorkflowSeq)

      setSubmitTransferFast(currentWorkflow?.workflow.submitTransferFast)

      if (currentWorkflow?.workflow.submitTransferDate) {
        const info = {
          ...data?.claimRequestHistoryDetail.info,
          conditions: nextActionWorkflow?.workflow.conditions,
          inputs: nextActionWorkflow?.workflow.inputs,
          values: infoValues,
        }
        const isValid = await handleValidateRequest(
          info,
          data?.claimRequestHistoryDetail.employeeId,
          data?.claimRequestHistoryDetail.type,
          data?.claimRequestHistoryDetail.id,
        )
        openApproveModal(isValid, setIsApproveModalOpen)
      } else {
        const title = data?.claimRequestHistoryDetail.info.title
        const amount = Number(requestAmount.replaceAll(",", ""))

        const amountDisplay = getAmountDisplay(amount)
        // @ts-ignore
        Modal.open({
          children: (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Icon width="40px" height="40px" name="CheckCircle" color={AppColor.Success} />
              <Box mt="16px">
                <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                  ยอมรับการเบิกสวัสดิการ ?
                </Typography>
              </Box>
              <Box mb="4px">
                <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                  อนุมัติการเบิก
                </Typography>
              </Box>
              <Box mb="16px">
                <Typography variant="h6" color={AppColor["Primary/Primary Text"]} isCurrency>
                  {title + " " + amountDisplay + " บาท"}
                </Typography>
              </Box>
            </div>
          ),
          onOk: () => {
            handleConfirmApprove()
            // @ts-ignore
            Modal.close()
          },
          okButtonLabel: "อนุมัติการเบิก",
          okButtonVariant: "contained",
          cancelButtonLabel: "ปิดหน้าต่างนี้",
          cancelButtonVariant: "outlined",
        })
      }
    }, [
      data?.claimRequestHistoryDetail,
      nextActionWorkflow?.workflow.conditions,
      nextActionWorkflow?.workflow.inputs,
      infoValues,
      handleValidateRequest,
      requestAmount,
      handleConfirmApprove,
    ])

    const handleCloseApproveModal = useCallback(() => {
      setIsApproveModalOpen(false)
    }, [])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])

    useEffect(() => {
      refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        setWorkflow(nextActionWorkflow, workflows, last, setWorkflowStep)
      }
    }, [data, nextActionWorkflow, refetch])

    useEffect(() => {
      setConfirmRefresh(showFooter, disableConfirmRefresh, enableConfirmRefresh)
    }, [enableConfirmRefresh, disableConfirmRefresh, showFooter])

    const isLoaded = useMemo(() => !detailLoading, [detailLoading])

    useEffect(() => {
      if (data?.claimRequestHistoryDetail) {
        const fetchedValues = data?.claimRequestHistoryDetail.info.values.inputs
        let initInputs = {}
        const inputs = getInputFromWorkflow(requestApproved, requestRejected, currentActionWorkflow, nextActionWorkflow)
        inputs.forEach((input: any) => {
          initInputs = { ...initInputs, [input.name]: getValue(input.type, input.name, fetchedValues) }
        })
        setValues({ ...initInputs })
        setDetailValues({ ...fetchedValues })
      }
    }, [
      currentActionWorkflow?.workflow.inputs,
      data,
      getValue,
      nextActionWorkflow?.workflow.inputs,
      requestApproved,
      requestRejected,
      setValues,
      currentUserIsHr,
    ])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequestHistoryDetail.info.documents.forEach((doc: any) => {
        const docs = data?.claimRequestHistoryDetail.info.values.documents[doc.name]
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: getValueOrDefault(docs, []),
        }
      })

      return initialDocuments
    }, [data?.claimRequestHistoryDetail.info.documents, data?.claimRequestHistoryDetail.info.values.documents, id])

    const [documentValues, setDocumentValues] = useState({})

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    useEffect(() => {
      const initInputs = prepareInitInputs(
        requestApproved,
        requestRejected,
        currentActionWorkflow,
        nextActionWorkflow,
        getStringValue,
        values,
      )

      const initValues: InfoValuesConfig = {
        inputs: initInputs,
        documents: { ...data?.claimRequestHistoryDetail.info.values.documents },
      }
      setInfoValues(initValues)
    }, [
      currentActionWorkflow?.workflow.inputs,
      data?.claimRequestHistoryDetail.info.values.documents,
      getStringValue,
      nextActionWorkflow?.workflow.inputs,
      requestApproved,
      requestRejected,
      values,
      data?.claimRequestHistoryDetail.info.inputs,
      currentUserIsHr,
    ])

    const handleFilesChange = useCallback(
      (values: any) => {
        setDocumentValues(values)
      },
      [documentValues],
    )

    const handleConfirmReject = useCallback(
      async (rejectReason: any, remark?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          await approveRequest({
            variables: {
              isApprove: false,
              seq: nextActionWorkflow?.seq,
              rejectReason: rejectReason,
              remark,
              ...approveParams,
              info: documentValues,
              requestName: data?.claimRequestHistoryDetail.type,
              employeeId: data?.claimRequestHistoryDetail.employeeId,
            },
          })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลแล้ว")
          disableConfirm()
          paths.claimRequestsPath({ tab: tab }).push()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <MuiIcon fontSize="large" color={AppColor["Error/Error Text"]} name="Warning"></MuiIcon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [
        approveRequest,
        nextActionWorkflow?.seq,
        approveParams,
        data?.claimRequestHistoryDetail.type,
        data?.claimRequestHistoryDetail.employeeId,
        disableConfirm,
        handleCloseErrorModal,
        documentValues,
        tab,
      ],
    )

    useEffect(() => {
      setPreview(requestApproved, requestRejected, setIsPreview)
    }, [requestApproved, requestRejected])

    useEffect(() => {
      setChildrenBirthDate(values, setFieldValue)
    }, [setFieldValue, values])

    const budgetYear = useMemo(() => {
      return setBudgetYear(data)
    }, [data])

    const customizeInputs = useMemo(() => {
      return mapCustomizeInput(
        requestApproved,
        requestRejected,
        currentActionWorkflow,
        isInstead,
        isPassAwayRequest,
        nextActionWorkflow,
      )
    }, [
      requestApproved,
      requestRejected,
      currentActionWorkflow?.workflow.inputs,
      isPassAwayRequest,
      isInstead,
      nextActionWorkflow?.workflow.inputs,
    ])

    const mappingPaymentTypeMessage = useMemo(() => {
      const paymentType = data?.claimRequestHistoryDetail.info.values.inputs["payment"]
      return mapPaymentType(paymentType)
    }, [data?.claimRequestHistoryDetail.info.values.inputs])

    const checkSummaryDetail = useMemo(() => {
      return isHaveSummaryDetail(nextActionWorkflow, requestApproved, requestRejected)
    }, [nextActionWorkflow?.workflow, requestApproved, requestRejected])

    return {
      handleClosePage,

      handleOpenApproveModal,
      handleCloseApproveModal,
      isApproveModalOpen,
      handleConfirmApprove,

      handleOpenRejectModal,
      handleCloseRejectModal,
      isRejectModalOpen,
      handleConfirmReject,

      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: customizeInputs,
      documents: getValueOrDefault(data?.claimRequestHistoryDetail.info.documents, []),
      inputValues: getValueOrDefault(infoValues.inputs, {}),
      documentValues,
      title: getValueOrDefault(data?.claimRequestHistoryDetail.info.title, ""),
      subTitle: data?.claimRequestHistoryDetail.info.subTitle,
      icon: data?.claimRequestHistoryDetail.info.icon,
      iconColor: data?.claimRequestHistoryDetail.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetail.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetail.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetail.info.type,
      name: data?.claimRequestHistoryDetail.type,
      approvedAmount: data?.claimRequestHistoryDetail.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetail.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      currentUser,

      workflows: getValueOrDefault(data?.claimRequestHistoryDetail.workflowSeq, []),
      workflowStep: getWorkflowStep(requestRejected, workflowStep),
      submitTransferFast,
      showFooter,

      requesterValues: getRequester(employee),
      requester: employee,
      approvalSection,
      hasFile,
      requestAmount,
      claimRequest: data?.claimRequestHistoryDetail,
      transferDate: getValueOrDefault(data?.claimRequestHistoryDetail.transferDate, null),
      rejectReasons: getValueOrDefault(nextActionWorkflow?.workflow.rejectedReasons, null),
      rejectReason: getValueOrDefault(data?.claimRequestHistoryDetail.rejectReason, null),
      rejectRemark: getValueOrDefault(data?.claimRequestHistoryDetail.remark, null),
      rejectedBy: getValueOrDefault(data?.claimRequestHistoryDetail.rejectedBy, null),
      transferFast: getValueOrDefault(data?.claimRequestHistoryDetail.info.transferFast, false),
      currentUserIsHr,
      isInstead,
      isInsurance,
      error: approveError,
      defaultTransferDate,

      disabledApproveButton,
      disabledRejectButton,
      isLoaded,

      claimFor: data?.claimRequestHistoryDetail.info.claimFor,
      affiliation: data?.claimRequestHistoryDetail.employee.title,
      email: getValueOrDefault(data?.claimRequestHistoryDetail.employee.officialMailId, "-"),
      employeeGroup: "-",
      phoneNumber: "-",

      master,
      isPreview,
      setFieldValue,
      values,
      detailValues,
      checkSummaryDetail,
      summary: getSummary(requestApproved, requestRejected, currentActionWorkflow, nextActionWorkflow),
      budgetValues: getValueOrDefault(allBudgets, {}),
      budgetDetail: getValueOrDefault(data?.claimRequestHistoryDetail.info?.budget, {}),
      year: budgetYear,
      yearlyBudget: getValueOrDefault(yearlyBudget, {}),
      budgets: getValueOrDefault(allBudgets?.list, {}),
      budgetList: getValueOrDefault(data?.claimRequestHistoryDetail.info?.budget?.list, {}),

      canEdit,

      isPassAwayRequest,

      mappingPaymentTypeMessage,

      validateClaimLoading,
      approveRequestLoading,

      tab,
      imageLoading,
      setImageLoading,

      handleFilesChange,
    }
  }),
)

const isHaveSummaryDetail = (nextActionWorkflow: any, requestApproved: any, requestRejected: any) => {
  const hasSummaryDetails = nextActionWorkflow?.workflow?.summary?.details?.length > 0
  return requestApproved || requestRejected || hasSummaryDetails
}

const getWorkflowStep = (requestRejected: any, workflowStep: any) => {
  return requestRejected ? Math.max(workflowStep - 1, 0) : workflowStep
}

const setConfirm = (showFooter: any, disableConfirm: any) => {
  if (!showFooter) {
    disableConfirm()
  }
}

const setConfirmRefresh = (showFooter: any, disableConfirmRefresh: any, enableConfirmRefresh: any) => {
  if (!showFooter) {
    disableConfirmRefresh()
  } else {
    enableConfirmRefresh()
  }
}

const setParams = (params: any, date: any, transferFast: any) => {
  if (date) params.transferDate = date
  if (transferFast !== undefined) params.isTransferFast = transferFast
}

const setPaymentType = (paymentType: any, info: any) => {
  if (paymentType !== undefined) info.values["inputs"]["payment"] = paymentType
}

const getAmountDisplay = (amount: any) => {
  return isNaN(amount) ? 0 : amount
}

const openApproveModal = (isValid: any, setIsApproveModalOpen: any) => {
  if (isValid) setIsApproveModalOpen(true)
}

const getInputFromWorkflow = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  nextActionWorkflow: any,
) => {
  return requestApproved || requestRejected
    ? currentActionWorkflow?.workflow.inputs
    : nextActionWorkflow?.workflow.inputs
}

const isShowFooter = (requestApproved: any, requestRejected: any, currentUserCanAction: any) => {
  if (requestApproved || requestRejected || !currentUserCanAction) return false
  return true
}

const mapApprovalSection = (requestRejected: any, requestApproved: any, data: any) => {
  if (requestRejected)
    return {
      label: "ปฏิเสธเมื่อ",
      approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
      detailLabel: "เหตุผล",
      detail: data?.claimRequestHistoryDetail.rejectReason,
    }
  if (requestApproved)
    return {
      label: "อนุมัติเมื่อ",
      approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
      detailLabel: "โอนเข้า",
      detail: "บัญชีเงินเดือน",
      sub: `(${dayjs(data?.claimRequestHistoryDetail.transferDate).format("DD/MM/YYYY")})`,
    }
  return undefined
}

const fetchYearlyBudget = (employee: any, data: any, refetchYearlyBudget: any) => {
  if (employee) {
    if (data?.claimRequestHistoryDetail.info.type === "ค่ารักษาพยาบาล") {
      const variables = {
        filters: {
          code: employee.employeeCode,
          fullName: "",
          year: new Date(data?.claimRequestHistoryDetail.createdAt).getFullYear(),
          isApp: true,
        },
      }

      refetchYearlyBudget({ variables: variables })
    }
  }
}

const getValueOrDefault = (value: any, defaultValue: any) => {
  return value || defaultValue
}

const getRequester = (employee: any) => {
  return employee ? employee.firstName + " " + employee.lastName : undefined
}

const getSummary = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  nextActionWorkflow: any,
) => {
  return requestApproved || requestRejected
    ? currentActionWorkflow?.workflow.summary
    : nextActionWorkflow?.workflow.summary
}

const checkIsPassaway = (requestTitle: any) => {
  return (
    requestTitle === "ค่าช่วยเหลือจัดการงานศพ" ||
    requestTitle === "ค่าเจ้าภาพงานศพ" ||
    requestTitle === "ค่าอุปกรณ์เคารพศพ"
  )
}

const fetchBudgetData = (employee: any, values: any, refetchBudget: any) => {
  if (employee?.id) {
    if (values.children_name) {
      refetchBudget({
        variables: { employeeId: employee.id, childrenName: values.children_name["name"] },
      })
    } else {
      refetchBudget({
        variables: { employeeId: employee.id, childrenName: "" },
      })
    }
  }
}

const getAmoutFieldNameForApprove = (status: any, history: any, amountFieldName: any) => {
  if (status) {
    return history?.info.values.inputs[amountFieldName]
  }
}

const getAmountFieldName = (func: any, history: any, amountFieldName: any) => {
  if (func !== undefined) {
    const result = func(history?.info, "update")
    return result
  }
  return amountFieldName
}

const checkInstead = (data: any) => {
  return data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id
}

const getTranferDate = (data: any) => {
  if (data?.claimRequestHistoryDetail.transferDate) return dayjs(data?.claimRequestHistoryDetail.transferDate)
  return dayjs()
}

const checkInsurance = (data: any) => {
  return (
    data?.claimRequestHistoryDetail.info.type === "ประกันกลุ่ม" ||
    data?.claimRequestHistoryDetail.info.type === "ประกันสังคม"
  )
}

const mapValue = (value: any, type: INPUT_TYPE) => {
  if (!value) return undefined
  switch (type) {
    case INPUT_TYPE.DATE:
      return stringToDate(value)
    case INPUT_TYPE.DATE_TIME:
      return stringToDate(value)
    case INPUT_TYPE.CURRENCY:
      return typeof value === "string" ? Number(value.replaceAll(",", "")) : value
    default:
      return value
  }
}

const mapStringValue = (value: any, formName: string, type: INPUT_TYPE, values: any) => {
  if (value === undefined) {
    return undefined
  }
  switch (type) {
    case INPUT_TYPE.TIME_PICKER:
      return dayjs(value).format("HH:mm")
    case INPUT_TYPE.DATE:
      return dayjs(value).format("DD/MM/YYYY")
    case INPUT_TYPE.DATE_TIME:
      return dayjs(value).format("DD/MM/YYYY, HH:mm")
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.CURRENCY:
    case INPUT_TYPE.SUM_AMOUNT:
    case INPUT_TYPE.CURRENCY_NUMBER:
      const newValue = typeof value === "string" ? Number(value.replaceAll(",", "")) : value
      return toCurrency(newValue, { minimumFractionDigits: 0 })
    case INPUT_TYPE.SELECT:
      return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
    case INPUT_TYPE.RELATION:
    case INPUT_TYPE.CHECKBOX:
      return value
    default:
      return value.toString()
  }
}

const checkValidateReq = async (
  validateRequest: any,
  onValidateError: any,
  info: any,
  requesterId: any,
  requestName: any,
  requestId: any,
) => {
  try {
    await validateRequest({
      variables: { info, employeeId: requesterId, requestName, requestId },
    })
  } catch (error: any) {
    if (isJson(error.message)) {
      onValidateError(error)
    }
    return false
  }
  return true
}

const openErrorModal = (e: any, handleCloseErrorModal: any) => {
  if (e) {
    // @ts-ignore
    Modal.alert({
      className: "deleteConFirmModal",
      title: "",
      children: (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box mb="16px">
            <MuiIcon fontSize="large" color={AppColor["Error/Error Text"]} name="Warning"></MuiIcon>
          </Box>
          <Box mb="16px">
            <Typography variant="body1" color="Text/Black2">
              {e.message}
            </Typography>
          </Box>
        </Box>
      ),
      okButtonLabel: "ตกลง",
      okButtonVariant: "contained",
      buttonWidth: "100%",
      onOk: handleCloseErrorModal,
    })
  }
}

const setWorkflow = (nextActionWorkflow: any, workflows: any, last: any, setWorkflowStep: any) => {
  if (nextActionWorkflow) {
    const nextActionIndex = workflows.findIndex((wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow))
    setWorkflowStep(nextActionIndex)
  } else if (last) {
    setWorkflowStep(workflows.length)
  }
}

const prepareInitInputs = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  nextActionWorkflow: any,
  getStringValue: any,
  values: any,
) => {
  let initInputs = {}
  if (requestApproved || requestRejected) {
    currentActionWorkflow?.workflow.inputs.forEach((input: any) => {
      initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
    })
  } else {
    nextActionWorkflow?.workflow.inputs.forEach((input: any) => {
      initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
    })
  }
  return initInputs
}

const setPreview = (requestApproved: any, requestRejected: any, setIsPreview: any) => {
  if (requestApproved || requestRejected) setIsPreview(true)
}

const setChildrenBirthDate = (values: any, setFieldValue: any) => {
  if (values.children_name) {
    setFieldValue("children_birthdate", values.children_name.birthDate)
  }
}

const setBudgetYear = (data: any) => {
  if (data) return new Date(data?.claimRequestHistoryDetail.createdAt).getFullYear() + 543 || "N/A"
}

const mapCustomizeInput = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  isInstead: any,
  isPassAwayRequest: any,
  nextActionWorkflow: any,
) => {
  if (requestApproved || requestRejected) {
    const inputs = currentActionWorkflow?.workflow.inputs.map((input: any) => {
      return {
        ...input,
        options:
          isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
            ? input.options.filter((input: any) => input !== "self")
            : input.options,
      }
    })
    return inputs
  } else {
    const inputs = nextActionWorkflow?.workflow.inputs.map((input: any) => {
      return {
        ...input,
        options:
          isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
            ? input.options.filter((input: any) => input !== "self")
            : input.options,
      }
    })
    return inputs
  }
}

const mapPaymentType = (paymentType: any) => {
  switch (paymentType) {
    case "โอนเข้าบัญชีเงินเดือน":
      return "โอนเงิน"
    case "เงินสด":
      return "จ่ายเงินสด"
    case "เช็ค":
      return "จ่ายเช็ค"
    default:
      return "โอนเงิน"
  }
}

const ApprovalPage = enhancer(ApprovalPageComponent)

export default ApprovalPage
