import { compose, withFormik, withHooks } from "enhancers"
import { gql } from "@apollo/client"
import { Box, Field, Form, Grid, Select, TextField, Typography, Button, Notification } from "components"
import { Dialog } from "@material-ui/core"
import { notifyError, Yup } from "utils/helper"
import { isEmpty } from "lodash"

const EditForm = (props: any) => (
  <Dialog open={props.isOpen} onClose={props.onCloseModal}>
    <Form>
      <Box>
        <Box display="flex" justifyContent="start" mb={4}>
          <Typography variant="h3" color="Text/Black2">
            แก้ไขข้อมูลครอบครัว
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              สมาชิกในครอบครัวที่ต้องการแก้ไข
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              type="text"
              name="dependentName"
              placeholder="สมาชิกในครอบครัวที่ต้องการแก้ไข"
              options={props.customOptions}
              fullWidth
            />
          </Grid>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              ชื่อ - นามสกุล
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              component={TextField}
              name="fullName"
              placeholder="ชื่อ - นามสกุล"
              fullWidth
              type="text"
              disabled={!props.values.dependentName}
            />
          </Grid>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              ความสัมพันธ์
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="relation"
              type="text"
              placeholder="ความสัมพันธ์"
              options={props.relationOptions}
              disabled={!props.values.dependentName}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box display="flex" flex={1} justifyContent="space-between" alignItems="center" width="100%" mt={4}>
          <Button
            variant="outlined"
            onClick={props.onCloseModal}
            style={{
              marginRight: 16,
              width: 100,
              flex: 1,
            }}
          >
            ปิดหน้าต่างนี้
          </Button>
          <Button variant="contained" type="submit" style={{ width: 100, flex: 1 }}>
            บันทึก
          </Button>
        </Box>
      </Box>
    </Form>
  </Dialog>
)

const API = {
  GET_DEPENDENTS: gql`
    query GET_DEPENDENTS($relationships: [String!]!, $requesterId: String!, $filterOptions: JSON!) {
      userDependents(
        input: { relationships: $relationships, requesterId: $requesterId, filterOptions: $filterOptions }
      ) {
        id
        name
        relation
      }
    }
  `,
  EDIT_USER_DEPENDENT: gql`
    mutation EDIT_USER_DEPENDENT(
      $requesterId: String!
      $userDependentId: String!
      $firstName: String!
      $lastName: String!
      $relation: String!
    ) {
      editUserDependent(
        input: {
          requesterId: $requesterId
          userDependentId: $userDependentId
          firstName: $firstName
          lastName: $lastName
          relation: $relation
        }
      ) {
        name
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValue: () => ({
      dependentName: "",
      fullName: "",
      relation: "",
      birthDate: "",
    }),
    validationSchema: Yup.object().shape({
      dependentName: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
      fullName: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
      relation: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const {
      requester,
      maxDate,
      options,
      claimType,
      isOpen,
      onClose,
      resetForm,
      values,
      setValues,
      setFieldValue,
      setFieldError,
      allUserDependents,
      refetch,
    } = props
    const { useMemo, useEffect, useState, useHandleSubmit, useCallback, useMutation } = hooks

    const [editUserDependent] = useMutation(API.EDIT_USER_DEPENDENT)

    const [familyOptions, setFamilyOptions] = useState(allUserDependents)

    useEffect(() => {
      setFamilyOptions(allUserDependents)
    }, [allUserDependents])

    useEffect(() => {
      if (values?.dependentName) {
        const id = values?.dependentName
        const info = familyOptions.find((item: any) => item.id === id)
        const { name, relation } = info
        setValues({
          dependentName: values?.dependentName,
          fullName: name,
          relation: relation,
        })
      }

      if (values?.dependentName === null) {
        resetForm()
      }
    }, [resetForm, setFieldValue, values?.dependentName, setValues, familyOptions])

    const relationOptions = useMemo(() => {
      switch (claimType) {
        case "ค่ารักษาพยาบาล":
          return [
            { label: "คู่สมรส", value: "spouse" },
            { label: "บุตร", value: "child" },
          ]
        case "ดูแลบุตร":
          return [{ label: "บุตร", value: "child" }]
        default:
          return [
            { label: "บิดา", value: "dad" },
            { label: "มารดา", value: "mom" },
            { label: "คู่สมรส", value: "spouse" },
            { label: "บุตร", value: "child" },
          ]
      }
    }, [claimType])

    const onCloseModal = useCallback(() => {
      onClose()
      resetForm()
    }, [onClose, resetForm])

    useHandleSubmit(async (values: any) => {
      const { dependentName, fullName, relation } = values
      const splitFullName = fullName.replace(/\s+/g, " ").trim().split(" ")
      const firstName = splitFullName[0]
      const lastName = splitFullName.slice(1).join(" ")
      const input = {
        requesterId: requester?.id,
        userDependentId: dependentName,
        firstName: firstName,
        lastName: lastName,
        relation: relation,
      }
      try {
        await editUserDependent({
          variables: { ...input },
        })
        refetch()
        // @ts-ignore
        Notification.success("เพิ่มข้อมูลครอบครัวสำเร็จ")
        onCloseModal()
      } catch (error: any) {
        console.log("error", error?.graphQLErrors[0])
        const errorMessage = JSON.parse(error?.graphQLErrors[0].message)
        const code = errorMessage.code
        if (code === "422-016") {
          const message = "ชื่อและนามสกุลนี้ซ้ำกับในระบบ"
          setFieldError("fullName", message)
        } else {
          notifyError("เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน")
        }
      }
    }, [])

    const customOptions = useMemo(() => {
      if (isEmpty(options)) {
        return []
      }
      return options.map((opt: any) => ({
        label: opt.label,
        value: opt.value.id,
      }))
    }, [options])

    return {
      values,
      relationOptions,
      maxDate,
      setFieldValue,
      customOptions: customOptions,

      isOpen,
      onCloseModal,
    }
  }),
)

export default enhancer(EditForm)
