import * as MaterialIcon from "@material-ui/icons"
import { ReactComponent as AddIcon } from "assets/icon/cart-icon.svg"
import { ReactComponent as NotFoundIcon } from "assets/icon/error_outline.svg"
import React from "react"
import { CustomMuiIconProps } from "./MuiIcon"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { ReactComponent as Report } from "assets/icon/report.svg"
import { ReactComponent as ExportFile } from "assets/icon/export_file.svg"
import { ReactComponent as AutoStories } from "assets/icon/using/auto_stories.svg"
import { ReactComponent as Computer } from "assets/icon/using/computer.svg"
import { ReactComponent as Dentistry } from "assets/icon/using/dentistry.svg"
import { ReactComponent as DeviceWareables } from "assets/icon/using/devices_wearables.svg"
import { ReactComponent as ElectricMeter } from "assets/icon/using/electric_meter.svg"
import { ReactComponent as Exercise } from "assets/icon/using/exercise.svg"
import { ReactComponent as EyeGlasses } from "assets/icon/using/eyeglasses.svg"
import { ReactComponent as Favorite } from "assets/icon/using/favorite.svg"
import { ReactComponent as MedicalServices } from "assets/icon/using/medical_services.svg"
import { ReactComponent as School } from "assets/icon/using/school.svg"
import { ReactComponent as SportsTennis } from "assets/icon/using/sports_tennis.svg"
import { ReactComponent as Vaccines } from "assets/icon/using/vaccines.svg"
import { ReactComponent as Home } from "assets/icon/home.svg"
import { ReactComponent as Payments } from "assets/icon/payments.svg"
import { ReactComponent as AttachEmail } from "assets/icon/attach_email.svg"
import { ReactComponent as StopCircle } from "assets/icon/stop_circle.svg"
import { ReactComponent as SadTear } from "assets/icon/using/sad-tear.svg"
import { ReactComponent as Wreath } from "assets/icon/using/wreath.svg"
import { ReactComponent as BreastFeeding } from "assets/icon/using/breastfeeding.svg"
import { ReactComponent as AddNotes } from "assets/icon/using/add_notes.svg"
import { ReactComponent as AdjustCircle } from "assets/icon/adjust.svg"
import { ReactComponent as RightArrowOutlined } from "assets/icon/using/arrow_right.svg"

// icon for eclaim category
import { ReactComponent as HealthAndWellNessNonTaxTitle } from "assets/icon/claim/My_Family_Medical_Treatment.svg"
import { ReactComponent as TrainingCoursesTitle } from "assets/icon/claim/Training_Courses.svg"
import { ReactComponent as MyFamilyHealthCheckUpTitle } from "assets/icon/claim/My_Family_Health_Check_Up.svg"
import { ReactComponent as FitnessTitle } from "assets/icon/claim/FitnessTitle.svg"
import { ReactComponent as PetLoverTitle } from "assets/icon/claim/Pet_Lover.svg"
import { ReactComponent as TravelTitle } from "assets/icon/claim/Travel.svg"
import { ReactComponent as SpaAndMassageTitle } from "assets/icon/claim/Spa_And_Massage.svg"
import { ReactComponent as EntertrainmentTitle } from "assets/icon/claim/Entertrainment.svg"
import { ReactComponent as MedicalTreatment } from "assets/icon/claim/medical_treatment.svg"
import { ReactComponent as HealthCheckUp } from "assets/icon/claim/check_up.svg"
import { ReactComponent as Vaccine } from "assets/icon/claim/vaccine.svg"
import { ReactComponent as Optical } from "assets/icon/claim/optical.svg"
import { ReactComponent as Training } from "assets/icon/claim/training.svg"
import { ReactComponent as VaccineFamily } from "assets/icon/claim/vaccine_for_family.svg"
import { ReactComponent as MedicalTreatmentorFamily } from "assets/icon/claim/medical_treatment_for_family.svg"
import { ReactComponent as MyFamilyHealthCheckUp } from "assets/icon/claim/my_family_heath_check_up.svg"
import { ReactComponent as Fitness } from "assets/icon/claim/fitness.svg"
import { ReactComponent as PersonalTrainer } from "assets/icon/claim/personal_trainer.svg"
import { ReactComponent as MedicalTreatmentForPet } from "assets/icon/claim/medical_treatment_for_pet.svg"
import { ReactComponent as PetHeathCheckUp } from "assets/icon/claim/pet_heath_check_up.svg"
import { ReactComponent as TravellingPackage } from "assets/icon/claim/travelling_package.svg"
import { ReactComponent as Accommodation } from "assets/icon/claim/accommodation.svg"
import { ReactComponent as Surgery } from "assets/icon/claim/surgery.svg"
import { ReactComponent as Tooth } from "assets/icon/claim/tooth.svg"
import { ReactComponent as GenderAffirmationSurgery } from "assets/icon/claim/gender_affirmation_surgery.svg"
import { ReactComponent as Spa } from "assets/icon/claim/spa.svg"
import { ReactComponent as Course } from "assets/icon/claim/course.svg"

type MuiIconCollections = keyof typeof MaterialIcon
export type IconName = keyof typeof SvgIcon | MuiIconCollections

interface IconProps extends Omit<CustomMuiIconProps, "name"> {
  name: IconName
}

export const SvgIcon = {
  point: Point,
  report: Report,
  export: ExportFile,
  auto_stories: AutoStories,
  computer: Computer,
  dentistry: Dentistry,
  devices_wearables: DeviceWareables,
  electric_meter: ElectricMeter,
  exercise: Exercise,
  eyeglasses: EyeGlasses,
  favorite: Favorite,
  medical_services: MedicalServices,
  school: School,
  sports_tennis: SportsTennis,
  vaccines: Vaccines,
  home: Home,
  payments: Payments,
  attach_email: AttachEmail,
  stop_circle: StopCircle,
  sadTear: SadTear,
  wreath: Wreath,
  breastFeeding: BreastFeeding,
  addNotes: AddNotes,
  adjust: AdjustCircle,
  arrowRight: RightArrowOutlined,
  healthAndWellNessNonTaxTitle: HealthAndWellNessNonTaxTitle,
  trainingCoursesTitle: TrainingCoursesTitle,
  myFamilyHealthCheckUpTitle: MyFamilyHealthCheckUpTitle,
  fitnessTitle: FitnessTitle,
  petLoverTitle: PetLoverTitle,
  travelTitle: TravelTitle,
  spaAndMassageTitle: SpaAndMassageTitle,
  entertrainmentTitle: EntertrainmentTitle,
  medicalTreatment: MedicalTreatment,
  healthCheckUp: HealthCheckUp,
  vaccine: Vaccine,
  optical: Optical,
  training: Training,
  vaccineFamily: VaccineFamily,
  medicalTreatmentorFamily: MedicalTreatmentorFamily,
  myFamilyHealthCheckUp: MyFamilyHealthCheckUp,
  fitness: Fitness,
  personalTrainer: PersonalTrainer,
  medicalTreatmentForPet: MedicalTreatmentForPet,
  petHeathCheckUp: PetHeathCheckUp,
  travellingPackage: TravellingPackage,
  accommodation: Accommodation,
  surgery: Surgery,
  tooth: Tooth,
  genderAffirmationSurgery: GenderAffirmationSurgery,
  spa: Spa,
  course: Course,
}

const IconComponent = ({ name, color, width = "20px", height = "20px", style, ...rest }: IconProps) => {
  delete rest.fill

  if (name) {
    if (name in SvgIcon) {
      const Svg = SvgIcon[name as keyof typeof SvgIcon]
      return <Svg fill={color} width={width} height={height} {...rest} />
    }
    if (MaterialIcon[name as MuiIconCollections])
      return React.createElement(MaterialIcon[name as MuiIconCollections], {
        ...rest,
        htmlColor: color,
        style: {
          ...style,
          width,
          height,
        },
      })
  }

  return <NotFoundIcon />
}

export default IconComponent
